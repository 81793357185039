<template>
  <b-overlay :show="showOverlay ">
    <b-col class="mb-1 bg-white p-0" cols="12">
      <DynamicBreadCrumb :items="pageBreadcrumb"></DynamicBreadCrumb>
    </b-col>
    <validation-observer ref="updateGameForm" tag="div">
      <b-card v-if="gameDetail" class="data-edit-wrapper">
        <h2>Game Details</h2>

        <b-form class="mt-2">
          <b-row>
            <b-col class="" md="4">
              <b-form-group
                  label="Name"
              >
                <validation-provider
                    #default="{ errors }"
                    rules="required"
                >
                  <b-form-input
                      id="data-edit-seoTitle"
                      v-model="gameDetail.name"
                      :state="errors.length > 0 ? false:null"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                  label="Short Description"
              >
                <validation-provider
                    #default="{ errors }"
                    rules="required"
                >
                  <b-form-input
                      id="data-edit-seoTitle"
                      v-model="gameDetail.shortDescription"
                      :state="errors.length > 0 ? false:null"
                  />
                </validation-provider>
              </b-form-group>

            </b-col>
            <b-col v-if="platforms" md="4">
              <b-form-group
                  label="Platforms"

              >
                <validation-provider
                    #default="{ errors }"
                    rules="required"
                >
                  <v-select
                      v-model="gameDetail.platforms"
                      :class="{'border-danger rounded' :gameDetail.platforms.length===0}"
                      :options="platforms"
                      :reduce="name => name.id"
                      dir="ltr"
                      label="name"
                      multiple
                  />
                </validation-provider>

              </b-form-group>
              <!--              <div v-if="gameDetail && gameDetail.gamePlatforms.length>0" class="d-flex align-items-center">-->
              <!--                <div v-for="(item,idx) in gameDetail.gamePlatforms" class="d-flex mx-1 badge pill bg-primary align-items-center">-->
              <!--                  <span class=" ">{{ item.platformName }}</span>-->
              <!--                  <feather-icon class="ml-25 cursor-pointer" icon="XIcon"  @click="removeGamePlatform(item.platformId,idx)"/>-->
              <!--                </div>-->
              <!--              </div>-->

            </b-col>

            <b-col class="my-1" cols="12">
              <b-form-group
                  label="Main Picture"
              >
                <div class="">
                  <div
                      class=" position-relative   border rounded  shadow     "
                      style="height: 10rem;width: 10rem"
                  >
                    <!--          <span-->
                    <!--              class="position-absolute cursor-pointer top-0 left-0 bg-white shadow d-flex align-items-center justify-content-center rounded-circle"-->
                    <!--              style="width: 2rem;height: 2rem"-->
                    <!--          >-->
                    <!--            <feather-icon class="text-danger" icon="TrashIcon"></feather-icon>-->
                    <!--          </span>-->
                    <img :src="gameDetail.imageUrl" alt="" style="object-fit: contain;height: 10rem;width: 10rem">
                  </div>

                </div>

              </b-form-group>

            </b-col>
            <b-col class="my-1" cols="12">
              <b-form-group
                  label="Upload New Picture"
              >
                <MediaHandler ref="mediaHandler" @sendData="setFiles"></MediaHandler>

              </b-form-group>

            </b-col>
            <b-col class="my-1" cols="12">
              <b-form-group
                  label="Slider Picture"
              >
                <div class="">
                  <div
                      class=" position-relative   border rounded  shadow     "
                      style="height: 10rem;width: 10rem"
                  >
                    <!--          <span-->
                    <!--              class="position-absolute cursor-pointer top-0 left-0 bg-white shadow d-flex align-items-center justify-content-center rounded-circle"-->
                    <!--              style="width: 2rem;height: 2rem"-->
                    <!--          >-->
                    <!--            <feather-icon class="text-danger" icon="TrashIcon"></feather-icon>-->
                    <!--          </span>-->
                    <img :src="gameDetail.sliderImageUrl" alt="" style="object-fit: contain;height: 10rem;width: 10rem">
                  </div>

                </div>

              </b-form-group>

            </b-col>
            <b-col class="my-1" cols="12">
              <b-form-group
                  label="Upload New Slider Picture"
              >
                <MediaHandler ref="mediaHandlerSlider" @sendData="setFilesSlider"></MediaHandler>

              </b-form-group>

            </b-col>
            <b-col cols="12">
              <b-form-group
                  label="Full Description"
              >

                <ckEditorMain ref="editorContent" :defaultContent="gameDetail.fullDescription"
                              @getEditorContent="setEditorContent"
                ></ckEditorMain>
              </b-form-group>

            </b-col>
          </b-row>
        </b-form>
      </b-card>
      <b-modal
          id="modal-delete"
          cancelTitle="Cancel"
          centered
          ok-title="Delete"
          title="Delete Game Service Category"
          @ok.prevent="deleteGameServiceCategory"
      >
        <div class="row">
          <div class="col-md-12">
            <span>Are you sure you want to delete this game service category ?</span>
          </div>
        </div>
      </b-modal>
      <b-card v-if="gameDetail">
        <b-row>
          <b-col cols="12" md="12">
            <h2>SEO</h2>
          </b-col>
          <b-col class="" cols="12" md="4">
            <b-form-group
                label="Seo Title"
            >
              <validation-provider
                  #default="{ errors }"
                  rules="required"
              >
                <b-form-input
                    id="data-edit-seoTitle"
                    v-model="gameDetail.seoTitle"
                    :state="errors.length > 0 ? false:null"
                />
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col class="" cols="12" md="4">
            <b-form-group
                label="Seo Url"
            >
              <validation-provider
                  #default="{ errors }"
                  rules="required"
              >
                <b-form-input
                    id="data-edit-seoTitle"
                    v-model="allCharactersToDash"
                    :state="errors.length > 0 ? false:null"
                />
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col class="" cols="12">
            <b-form-group
                label="Seo Description"
            >
              <validation-provider
                  #default="{ errors }"
                  rules="required"
              >

                <b-form-textarea
                    id="data-edit-seoTitle"
                    v-model="gameDetail.seoDescription"
                    :state="errors.length > 0 ? false:null"
                />
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col class="p-0 pl-1" cols="12">
            <div class="col-md-12  p-0 ">
              <!--- serp Desktop --->
              <div class="serp-preview d-lg-flex d-none flex-column align-items-start">
                <div class="serp-title">
                  <h4 style="color: rgb(26, 13, 171)!important">
                    {{ gameDetail.seoTitle }}
                  </h4>
                </div>
                <div class="serp-url">
                  <h5 class="d-flex align-items-center flex-wrap" style="color: rgb(0, 102, 33)!important">
                    <span>https://skycoach.com/<span class="serp-arrow"></span></span>
                    <span v-if="gameDetail.seoUrl" style="overflow-wrap: anywhere">
                      {{ gameDetail.seoUrl.replaceAll(' ', '-') }}
                    </span>

                  </h5>
                </div>
                <div class="serp-description">
                  <h6 style="overflow-wrap: anywhere">
                    {{ gameDetail.seoDescription }}
                  </h6>
                </div>
              </div>
              <!--- serp Mobile --->
              <div class="serp-preview d-lg-none  p-1 d-flex flex-column align-items-start">
                <div class="serp-title">
                  <h6>
                    {{ gameDetail.seoTitle }}
                  </h6>
                </div>
                <div class="serp-url d-flex align-items-center flex-wrap">

                  <small>{{ gameDetail.seoUrl.replaceAll(' ', '-') }}/</small>
                  <small>
                    https://skycoach.com/
                    <small class="serp-arrow"></small>
                  </small>
                </div>
                <div class="serp-description">
                  <small>
                    {{ gameDetail.seoDescription }}
                  </small>
                </div>
              </div>
            </div>
          </b-col>
          <b-col cols="3">
            <b-button
                class="btn-tour-skip mr-1"
                variant="primary"
                @click="updateGame"
            >
              <span class="mr-25 align-middle">Submit</span>

            </b-button>
          </b-col>
        </b-row>
      </b-card>
      <b-card v-if="gameDetail">
        <b-row>
          <b-col cols="12" md="12">
            <h2>Game Service Categories</h2>
          </b-col>
          <b-col class="my-1 d-flex align-items-center " cols="12" md="1">
            <b-button
                class="btn-tour-skip mr-1 mt-1"
                variant="primary"
            >
              <b-link :to="`/apps/games/game-service-categories/create-game-service-category?gameId=${gameDetail.id}`">
                <span class="mr-25 align-middle text-white">Create</span>
              </b-link>

            </b-button>
          </b-col>
          <b-col class="my-1" cols="12" md="3">
            <b-form-group label="Search By">
              <debouncer @setValue="getsearchValue"></debouncer>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-table
                ref="refUserListTable"
                :fields="myTableColumns"
                :items="gameServiceCategories"
                :sort-by.sync="sortBy"
                bordered
                class="position-relative"
                empty-text="Nothing found !"
                primary-key="id"
                responsive
                show-empty
                striped
            >

              <template #cell(imageUrl)="data">
                <img :src="data.item.imageUrl" alt="" style="width: 5rem;height: 5rem;object-fit: contain">
              </template>
              <template #cell(operation)="data">
                <div class="d-flex align-items-center  w-100">
                  <b-link
                      :to="`/apps/games/game-service-categories/manage-game-service-category?gameId=${$route.query.gameId}&gameServiceCategoryId=${data.item.id}`"
                  >
                    <feather-icon
                        class="text-primary"
                        icon="EditIcon"
                        size="20"
                    />
                  </b-link>
                  <span v-b-modal.modal-delete class="cursor-pointer mx-1"
                        @click="setSelectedgameCategoryService(data.item)"
                  >
              <feather-icon
                  class="text-danger"
                  icon="TrashIcon"
                  size="20"
              />
              </span>
                </div>
              </template>
            </b-table>

          </b-col>
          <b-col cols="12">
            <div class="mx-2 mb-2">
              <b-row>
                <b-col
                    class="d-flex align-items-center justify-content-center"
                    cols="12"
                    sm="12"
                >
                  <!--            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>-->
                </b-col>
                <!-- Pagination -->
                <b-col
                    class="d-flex align-items-center justify-content-center justify-content-sm-end"
                    cols="12"
                    sm="6"
                >
                  <b-pagination
                      v-model="currentPage"
                      :per-page="perPage"
                      :total-rows="totalCount"
                      class="mb-0 mt-1 mt-sm-0"
                      first-number
                      last-number
                      next-class="next-item"
                      prev-class="prev-item"
                  >
                    <template #prev-text>
                      <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                      />
                    </template>
                  </b-pagination>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </b-card>
    </validation-observer>


  </b-overlay>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import {
  BButton,
  BCard,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BLink,
  BModal,
  BOverlay,
  BPagination,
  BRow,
  BTable
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import ckEditorMain from '@/views/components/ckEditorMain'
import Helper from '@/libs/Helper'
import MediaHandler from '@/views/components/utilities/MediaHandler.vue'
import { GetGameDetail, GetGameServiceCategoriesByGameId, RemoveGamePlatform, UpdateGame } from '@/libs/Api/games'
import { DeleteGameServiceCategory } from '@/libs/Api/gameServiceCategory'
import Debouncer from '@/views/components/debouncer.vue'
import DynamicBreadCrumb from '@/views/components/utilities/DynamicBreadCrumb.vue'
import { GetAllPlatforms } from '@/libs/Api/platforms'

export default {
  components: {
    DynamicBreadCrumb,
    Debouncer,
    MediaHandler,
    BFormTextarea,
    BForm,
    ValidationProvider,
    ValidationObserver,
    ckEditorMain,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BButton,
    BTable,
    BLink,
    BPagination,
    BOverlay,
    BModal,
    vSelect,
  },
  name: 'manage-game',
  title: 'manage game',
  data() {
    return {
      required,
      showOverlay: false,
      baseUrl: Helper.baseUrl,
      gameDetail: null,
      platforms: null,

      selectedGameServiceCategory: null,
      currentPage: 1,
      sortBy: '',
      perPage: 10,
      gameServiceCategories: null,
      searchQuery: '',
      totalCount: null,
      myTableColumns: [
        {
          key: 'id',
          label: '#',
          sortable: true
        },
        {
          key: 'title',
          label: 'title',
          sortable: true
        },
        {
          key: 'subTitle',
          label: 'subTitle',
          sortable: true
        },
        {
          key: 'operation',
          label: 'operation'
        },
      ],
      pageBreadcrumb: [
        {
          text: 'Manage Games',
          href: '/apps/games/manage-games',
          active: false
        },
        {
          text: `Manage Game`,
          href: `/apps/games/manage-game?gameId=${this.$route.query.gameId}`,
          active: true
        },
      ],

    }
  },
  computed: {
    allCharactersToDash: {
      get: function () {
        return this.gameDetail.seoUrl
      },
      set: function (newValue) {
        // This setter is getting number, replace all commas with empty str
        // Then start to separate numbers with ',' from beginning to prevent
        // from data corruption
        if (newValue) {
          this.gameDetail.seoUrl = newValue
          // Remove all characters that are NOT number
          this.gameDetail.seoUrl = this.gameDetail.seoUrl.replace(/[+*!@#$%^&*()_;:~`»«,×='"|<>/?{}\-/\.]/g, '-')
          this.gameDetail.seoUrl = this.gameDetail?.seoUrl?.replaceAll(' ', '-')
        } else if (!newValue || this.gameDetail.seoUrl === '') {
          this.gameDetail.seoUrl = null
        }
      },
    },
  },
  watch: {
    currentPage: function (val) {
      this.getGameServiceCategoriesByGameId()
    }
  },
  async created() {
    await Promise.all([
      this.getGameDetail(),
      this.getGameServiceCategoriesByGameId(),
      this.getPlatforms()
    ])
  },
  methods: {
    async removeGamePlatform(id, idx) {
      let _this = this
      _this.showOverlay = true
      let removeGamePlatform = new RemoveGamePlatform(_this)
      removeGamePlatform.setParams({
        gameId: _this.$route.query.gameId,
        platformId: id
      })
      await removeGamePlatform.fetch(function (content) {
        _this.$toast({
          component: ToastificationContent,
          props: {
            title: `success`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `Platform Removed`,
          },
        })
        _this.gameDetail.gamePlatforms.splice(idx, 1)
        _this.showOverlay = false
      }, function (error) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: error.message,
            title: `error`,
            variant: 'danger',
          },
        })
        _this.showOverlay = false
      })
    },
    async getPlatforms() {
      let _this = this
      _this.showOverlay = true
      let getAllPlatforms = new GetAllPlatforms(_this)
      let data = {
        pageNumber: 1,
        count: 999,
      }
      getAllPlatforms.setParams(data)
      await getAllPlatforms.fetch(function (content) {

        _this.platforms = content.data
        _this.showOverlay = false
      }, function (error) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: error.message,
            title: `error`,
            variant: 'danger',
          },
        })
        _this.showOverlay = false
      })
    },

    getsearchValue(query) {
      this.searchQuery = query
      this.getGameServiceCategoriesByGameId()
    },
    setSelectedgameCategoryService(item) {
      this.selectedGameServiceCategory = JSON.parse(JSON.stringify(item))
    },
    async deleteGameServiceCategory() {
      let _this = this
      _this.showOverlay = true
      let deleteGameServiceCategory = new DeleteGameServiceCategory(_this)
      deleteGameServiceCategory.setParams({ gameServiceCategoryId: _this.selectedGameServiceCategory.id })
      await deleteGameServiceCategory.fetch(function (content) {
        _this.$toast({
          component: ToastificationContent,
          props: {
            title: `success`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `game successfully deleted`,
          },
        })
        _this.getGameServiceCategoriesByGameId()
        _this.$bvModal.hide('modal-delete')
        _this.showOverlay = false
      }, function (error) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: error.message,
            title: `error`,
            variant: 'danger',
          },
        })
        _this.showOverlay = false
      })

    },
    async getGameServiceCategoriesByGameId() {
      let _this = this
      _this.showOverlay = true

      let getGameServiceCategoriesByGameId = new GetGameServiceCategoriesByGameId(_this)
      let data = {
        PageNumber: _this.currentPage,
        count: _this.perPage,
        search: _this.searchQuery
      }
      getGameServiceCategoriesByGameId.setPartialUrl({ id: _this.$route.query.gameId })
      getGameServiceCategoriesByGameId.setParams(data)
      await getGameServiceCategoriesByGameId.fetch(function (content) {
        _this.showOverlay = false
        _this.gameServiceCategories = content.data.data
        _this.totalCount = content.data.totalCount

      }, function (error) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: error.message,
            title: `error`,
            variant: 'danger',
          },
        })
        _this.showOverlay = false
      })
    },
    setEditorContent(content) {
      this.gameDetail.fullDescription = content
    },
    async getGameDetail() {
      let _this = this
      _this.showOverlay = true

      let getGameDetail = new GetGameDetail(_this)
      let data = {
        id: _this.$route.query.gameId
      }
      getGameDetail.setParams(data)
      await getGameDetail.fetch(function (content) {
        _this.showOverlay = false
        _this.gameDetail = content.data

      }, function (error) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: error.message,
            title: `error`,
            variant: 'danger',
          },
        })
        _this.showOverlay = false
      })
    },
    setFiles(data) {
      if (data) {
        this.gameDetail['imageFile'] = data
      }
    },
    setFilesSlider(data) {
      this.gameDetail['SliderImageFile'] = data

    },
    async updateGame() {
      let _this = this
      Helper.validateForm(_this, 'updateGameForm', async () => {
        _this.showOverlay = true
        _this.$refs.mediaHandler.sendFiles()
        _this.$refs.mediaHandlerSlider.sendFiles()
        let tempGameDetail = this.gameDetail
        let tempPlatforms = []
        if (this.gameDetail.platforms) {
          this.gameDetail.platforms.forEach((item) => {
            if (item.id) {
              tempPlatforms.push(item.id)
            } else {
              tempPlatforms.push(item)
            }
          })
        }
        tempGameDetail.platforms = tempPlatforms
        let myForm = await Helper.makeFormData(tempGameDetail)
        let updateGame = new UpdateGame(_this)
        updateGame.setPartialUrl({ id: this.$route.query.gameId })
        updateGame.setParams(myForm)
        await updateGame.sendFormData(function (content) {
          _this.showOverlay = false
          if (content.isSuccess) {
            _this.$toast({
              component: ToastificationContent,
              position: 'bottom-center',
              props: {
                title: `Done`,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            _this.getGameDetail()
            _this.$refs.mediaHandler.clearMedia()
            _this.$refs.mediaHandlerSlider.clearMedia()
          } else {
            return _this.$toast({
              component: ToastificationContent,
              position: 'bottom-center',
              props: {
                text: content.message,
                title: `error`,
                variant: 'danger',
              },
            })
          }
        }, function (error) {
          _this.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              text: error.message,
              title: `error`,
              variant: 'danger',
            },
          })
          _this.showOverlay = false
        })
      })
    }
  }
}
</script>

<style scoped>

</style>
